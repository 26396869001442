import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Draggable, Droppable } from "react-beautiful-dnd";
import { LoanPhaseDto } from 'src/backend';
import { Route } from 'src/constants/ui';
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import { Loan } from 'src/types/loan';

import { NextLinkComposed } from '../common/next-link-composed';
import { LoanCard } from '../loans/loan-card';
import { LoansKanbanCardSkelton } from '../loans-kanban/loans-kanban-card-skeleton';

interface LoansColumnProps {
    isDragEnabled?: boolean;
    loans: Loan[];
    isLender: boolean;
    isLoading: boolean;
    loanPhase: LoanPhaseDto;
    className?: string;
    filterQuery?: string;
    onPinLoan?: (loanId: string) => void;
}

export const getKanbanColumnSx = (isDraggingOver: boolean, isLender: boolean) => (theme) => ({
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    mb: 2,
    overflow: 'auto',
    display: 'flex',
    backgroundColor: isLender ? themeV2.neutral.grey[6] : 'transparent',
    boxShadow: {
        xs: 'none',
    },
    flexShrink: 0,
    transition: theme.transitions.create('backgroundColor', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(isDraggingOver && {
        backgroundColor: themeV2.colors.blue[10],
        transition: theme.transitions.create('backgroundColor', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    })
});

const getItemSx = (isDragging: boolean, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    '& > .MuiPaper-root': {
        boxShadow: isDragging ? 10 : 1,
    },
    // styles we need to apply on draggables
    ...draggableStyle
});


export const LoanKanbanPhase = (props: LoansColumnProps) => {
    return (<Droppable
        droppableId={props.loanPhase.id}
        isDropDisabled={!props.isDragEnabled}>
        {(provided, droppableSnapshot) => (
            <Paper
                key={props.loanPhase.id}
                className={`${props.className} scrollbar-stable gap-3 p-4`}
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={getKanbanColumnSx(droppableSnapshot.isDraggingOver, props.isLender)}>
                {props.loans.map((loan, index) => (
                    <Draggable
                        isDragDisabled={!props.isDragEnabled}
                        key={loan.id}
                        draggableId={loan.id}
                        index={index}>
                        {(provided, draggableSnapshot) => (
                            <Box
                                className='loan-card-container w-full lg:min-w-[356px] w-full max-w-sm max-h-64'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                    width: '100%',
                                    position: 'relative',
                                    '&:hover > button': {
                                        display: {
                                            xs: 'none',
                                            sm: (droppableSnapshot.isDraggingOver) ? 'none' : 'flex'
                                        }
                                    },
                                    ...getItemSx(
                                        draggableSnapshot.isDragging,
                                        provided.draggableProps.style
                                    )
                                }}>

                                <NextLinkComposed
                                    className='w-full'
                                    to={{ pathname: Route.SINGLE_LOAN, query: { loanId: loan.id } }}
                                >
                                    <LoanCard
                                        onPin={props.onPinLoan}
                                        highlightText={props.filterQuery}
                                        loan={loan}
                                        isUserALender={props.isLender}
                                    />
                                </NextLinkComposed>
                            </Box>
                        )}
                    </Draggable>
                ))}
                <div
                    style={{ opacity: props.isLoading ? 1 : 0 }}
                    className='loan-card-container'>
                    <LoansKanbanCardSkelton />
                </div>
                {provided.placeholder}
            </Paper>
        )}
    </Droppable>
    );
};

import { DropResult } from "react-beautiful-dnd"
import { BorrowerListViewColumns, LoanListViewColumns } from "src/constants/loan"
import { useCustomizedTableValues } from 'src/contexts/customized-table-context';
import { useKanbanLoans } from 'src/contexts/loans-kanban-context';
import { useUser } from "src/hooks/use-user";
import { LoanTableColumn } from "src/types/loan"

export const useLoanListViewTable = () => {
    const { state, filterState, onSetSortedListColumns, onSortByColumn, onPinLoan } = useKanbanLoans();
    const { selectedHeaders, allHeaders, toggleHeader } = useCustomizedTableValues();
    const { isLender } = useUser();
    const sortedColumns = sortColumns(state.sortedListViewColumns, isLender
        ? LoanListViewColumns
        : BorrowerListViewColumns);

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = [...sortedColumns]
        const [reorderedItem] = items.splice(result.source.index - 1, 1);
        items.splice(result.destination.index - 1, 0, reorderedItem);

        onSetSortedListColumns(items.map(item => item.id));
    }

    return {
        onDragEnd: handleDragEnd,
        onSortByColumn,
        toggleHeader,
        onPinLoan,
        columns: sortedColumns,
        orderByColumn: state.orderByColumn,
        orderDirection: state.orderDirection,
        selectedHeaders,
        allHeaders,
        isLender,
        filterQuery: filterState.filterQuery
    } as const
}

const sortColumns = (ids: string[], columns: LoanTableColumn[]) => {
    return columns.sort((a, b) => {
        return ids?.indexOf(a.id) - ids?.indexOf(b.id)
    });
}
